<template>
    <div class="container">
    

        <!--

                <div class="form-row">
                    <div class="form-group col-md-12">
                       <label for="valor">Valor</label>
                       <input name="valor" maxlength="15" type="tel" v-model="valor"  class="form-control" id="valor" placeholder="Valor" pattern="([0-9]{1,3}\.)?[0-9]{1,3},[0-9]{2}$">
                                      
                    </div>
                </div>



        -->    



        <div v-show="msg" class="alert"  :class="tipoAlerta" role="alert">
            <h5>{{msg}}</h5>
        </div>

        <div>
            <h3>Clientes Prospectados</h3> 
            
        </div>

        <div class="painel-superior">

            <div v-if="tOp == 'view'">
               <button  class="novo-btn" @click="novoCliente">Novo</button>
            </div>
            <div v-else>
               <button class="novo-btn" @click="salvarCliente">Salvar</button>
               <button class="novo-btn" @click="cancelarCliente">Cancelar</button> 
            </div>

        </div>

        <div id="list-table" v-if="tOp == 'view'">
           
            <div>
                <br /><br />
               
                <div id="head-table">
                    <div class="order-id"></div>
                    <div>Razão Social</div>
                    <div>WhatsApp</div> 
                    <div>Ações</div>  
                </div>
               
               
                <div id="det-rows">
                    <div class="det-row" v-for="cliente in clientes" :key="cliente.id">
                        <div class="order-number">{{cliente.id}}</div>
                        <div>{{cliente.nome}}</div>
                        <div>{{cliente.celular}}</div>                        
                      
                        <div >
                            <button class="alterar-btn" @click="carregarCliente(cliente.id)">Alterar</button>
                            <button class="delete-btn" @click="deleteCliente(cliente.id)">Excluir</button>
                        </div>
                    </div> 
                    
                    <nav aria-label="...">
                        <ul class="pagination">
                            <li  @click="paginaAnterior()"  class="page-item"><a class="page-link" href="#" tabindex="-1">Anterior</a></li>
                            <li  v-for="pagina in totalPaginas" :key="pagina"    @click="calcOffSet(pagina)" class="page-item" :class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                            <li  @click="proximaPagina()" class="page-item"><a class="page-link" href="#">Próximo</a></li>
                        </ul>
                    </nav>
                                  
                
                </div>

            </div>

        </div> 
        
        <div v-if="tOp != 'view'">
            <p> <strong>cadastrar Cliente</strong> </p>
            
            <form>           

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="cnpj">CNPJ</label>
                        <input name="cnpj"    v-model="cnpj" type="text" class="form-control" id="cnpj" placeholder="Digite somente números neste campos"  minlength="14" maxlength="14">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="nome">Razão Social</label>
                        <input name="nome" v-model="nome" type="text" class="form-control" id="nome" placeholder="Nome" required>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="telefones">Telefones</label>
                        <input name="telefones" v-model="telefones" type="text" class="form-control" id="telefones" placeholder="Digite os números de telefone" required>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="celular">WhatsApp</label>
                        <input name="celular" v-model="celular" type="tel" class="form-control" id="celular" placeholder="Digite somente números neste campos com DDD" required>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="email">E-mail</label>
                        <input name="email" v-model="email" type="email" class="form-control" id="email" placeholder="E-mail">
                    </div>
                </div>


                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="contato">Contato</label>
                        <input name="contato" v-model="contato" type="text" class="form-control" id="contato" placeholder="Digite o contato" required>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="responsavel">Responsável</label>
                        <input name="responsavel" v-model="responsavel" type="text" class="form-control" id="responsavel" placeholder="Digite o responsável">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="celular1">WhatsApp Responsável</label>
                        <input name="celular1" v-model="celular1" type="tel" class="form-control" id="celular1" placeholder="Digite somente números neste campos com DDD">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="observacao">Observação</label>
                        <input name="observacao" v-model="observacao" type="text" class="form-control" id="observacao" placeholder="Observação">
                    </div>
                </div>
                    

            </form>

          

        </div>

    </div>
</template>

<script>

import Message from '../../Message.vue'
import {urlAPI, tipoOp} from '../../../config.js'
import ProspeccaoCliente from '../Cadastro/ProspeccaoCliente.vue'
import {validarCNPJ, validarCelular} from "../../../funcoesDiversas.js"





export default {
    name:"ClienteListForm",
    Components:{
      Message,
      ProspeccaoCliente  
    },
    data(){
        return{
            

            //Campos 2
            hashnumber:null,
            idusuario:null,
            idadmin:null,
            now:null,


            //Campos 1 
            clientes:null,
            cliente_id:null,
            nome:null,
            email:null,
            cnpj:null,
            celular:null,
            celular1:null,
            observacao:null,
            
            telefones:null,
            contato:null,
            responsavel:null,
            datacad:null,

            
            //Variável de controle da página
            offset:0,
            limit:10,
            totalPaginas:0,
            paginaAtual:1,
            msg:null,            
            tOp:null,
            tipoAlerta:null,
            filtro:null,
            sort:null

        }
        
    },
    
    
    monted(){

    },

    created(){

        /***
         * 
        this.now = new Date()
        this.datacad = this.now.getFullYear()+'-'+(this.now.getMonth())+'-'+this.now.getDate()
        console.log('Data atual:',this.now)
        * 
        */


    },

    methods:{
       
       
       isActive(pagina){
            return pagina == this.paginaAtual ? 'active' : ''
        },

        proximaPagina(){
            if (this.paginaAtual >= this.totalPaginas){
                return
            }
            this.paginaAtual ++
            this.calcOffSet(this.paginaAtual)
        },
        
        paginaAnterior(){

            if (this.paginaAtual <= 1){
               return 
            }


            this.paginaAtual --


            this.calcOffSet(this.paginaAtual)
        },

        calcOffSet(pagina){

            this.offset = (pagina * this.limit) - this.limit
            
            this.getClientes()

            this.paginaAtual = pagina

            console.log('pagina ',pagina)
        },

        limparCampos(){
           
           //Campo 1
           this.cliente_id = null
           this.nome = ""
           
           this.email = ""
           this.cnpj = ""
           this.celular = ""
           this.celular1 = ""
           this.observacao = ""
           

           this.telefones = ""
           this.contato = ""
           this.responsavel = "" 
           this.datacad = null           

           //Campo 2
           this.hashnumber = ""
           this.idusuario = this.$store.getters.getUserID
           this.idadmin =  this.$store.getters.getIdAdmin
           

        },        
        
        
        limparMsg(){
           this.msg = null
           this.tipoAlerta = null 
        },


        cancelarCliente(){
            
            this.tOp = tipoOp.view
        },
        
        
        
        
        async salvarCliente(){

          if (!validarCNPJ(this.cnpj)){
              alert('CNPJ inválido')
              return 
          }

          if (!validarCelular(this.celular)){
              alert('WhatsApp Inválido, preencha o número com DDD. O númenro deve ter até 11 dígitos! ')
              return
          }
        

          if (!validarCelular(this.celular1)){
              alert('WhatsApp Inválido, preencha o número com DDD. O WHATSAPP do responsável deve ter até 11 dígitos! ')
              return
          }



            let data = {
                
                id:  this.cliente_id,
                nome:  this.nome,
                celular:this.celular,
                celular1:this.celular1,
                cnpj:this.cnpj,
                observacao: this.observacao,
                email:this.email,
               
                telefones:this.telefones,
                contato:this.contato,
                responsavel:this.responsavel,
                datacad:this.datacad,
                
                hashnumber:this.hashnumber,
                idusuario: this.idusuario,
                idadmin: this.idadmin


           }

           let dataJson = JSON.stringify(data)

           let endpoint;
           let methods;
           
           if (this.tOp == tipoOp.create){
               endpoint = '/prospeccao/clientes/insert'
               methods = "POST"
               delete data.id //Para inclusão no banco é necessário excluir o id  
           }else if (this.tOp == tipoOp.update){
               endpoint = '/prospeccao/clientes/update' 
               methods = "PUT"
               
           } 


           console.log(data)

            try {
                const req = await fetch(urlAPI+endpoint,{
                    method:methods,
                    headers: {"content-type":"application/json"},
                    body:dataJson 
                })
           
                const res = await req.json()                
                
                console.log('Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log(dataJson)                
                
                if (res.Result == 'OK'){

                   this.tipoAlerta = "alert-success"
                   
                   this.msg = res.Message

                   // clear message
                   setTimeout(() => this.limparMsg(), 3000)

                
                } else {

                    this.tipoAlerta = "alert-warning"

                    this.msg = res.Message
                    
                    if(dataJson.indexOf("idx_email") > 0){
                        
                        this.tipoAlerta = "alert-warning"

                        this.msg = "Email já cadastrado"
                    }                   

                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)


                }
                
                let result = JSON.parse(dataJson)                
                
                console.log(this.msg)               
               
               console.log(result.Result, result.Message)

               this.tOp = tipoOp.view

               this.getClientes()

               this.limparCampos()
           
           } catch (error) {
                
                this.tipoAlerta = "alert-danger"
                this.msg = 'Ocorreu um erro ao processar informações' 

                console.log('Error: ',error);

                // clear message
                setTimeout(() => this.limparMsg(), 3000)


           }

            
            
        },

        async getClientes(){
            console.log(urlAPI)
            
            //Ordenação
            this.sort = 'nome'

            
            
            
            
            //Filtrar por uma determinada informação 
            //this.filtro = "nomeusuario = 'master'"
            
            //TODO: Quando realizar o GET enviar o idusuario, identificar se é colaborador, caso seja ele seja colaborador então deverá montar json no servidor somente com dados do colaborador.
            //TODO: Mudar rotina de filtrar dados do usuário colaborador logado para que seja responsabilidade do servidor com isso aumentará a segurança da informação
            //Filtrar por uma determinada informação 
            
            this.filtro = `idadmin = ${this.$store.getters.getIdAdmin}`
            
            if (this.$store.getters.getIsColaborador) {
               this.filtro = this.filtro + ` and idusuario = ${this.$store.getters.getUserID}`   
            }
            
            
            try {

                 const req = await fetch(urlAPI+`/prospeccao/clientes/list/offset/${this.offset}/limit/${this.limit}/filtro/${this.filtro}/sort/${this.sort}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                     
                })

                const res = await req.json()
                
                this.clientes = res.Prospeccao_Clientes

                //this.totalGeralRegistro = res.totalGeralRegistro

               
               this.clientes != null ?  this.totalPaginas = Math.ceil(res.totalGeralRegistro / this.limit) : 0

                
                console.log(this.clientes) 
               
                 
                
            } catch (error) {
                console.log("erro ao listar usuários", error)
            }


        },

        async deleteCliente(id){
            
            const resultado = confirm("Deseja excluir Cliente para prospectar " + "" + " ?");
            if (resultado !== true){
                return
            }

            try {

               const req = await fetch(urlAPI+`/prospeccao/clientes/delete/${id}`,{
                    method:"DELETE",
                  
                     
                })

                const res = await req.json()
                
                //this.Clientes = res 

                console.log(res)

                console.log(id)

                //Dependendo da situação o correto é excluir a informação da tela ao invés de fazer nova requisição para o servidor
                this.getClientes()

                //Transforma JSON em String
                //let dataJson = JSON.stringify(res)
                
               //Transforma String em JSON 
               // let result = JSON.parse(dataJson)  

               this.tipoAlerta = "alert-success"
               
               this.msg=res.Message

               // clear message
               setTimeout(() => this.limparMsg(), 3000)

                
            } catch (error) {
                console.log(error)
            }
        },

        async carregarCliente(id){
            try{
                
                
                const req = await fetch(urlAPI+`/prospeccao/clientes/find/${id}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                  
                     
                })

                const res = await req.json()
                

                console.log(res)

                console.log(id)
              
                //Carrega dados 1
                this.cliente_id = res.id
                this.nome = res.nome
                this.email = res.email
                this.cnpj = res.cnpj
                this.celular = res.celular
                this.celular1 = res.celular1
                this.observacao = res.observacao

                this.telefones = res.telefones
                this.contato = res.contato
                this.responsavel = res.responsavel
                this.datacad = res.datacad
                
                //Carrega dados 2
                this.hashnumber = res.hashnumber
                this.idusuario = res.idusuario
                this.idadmin = res.idadmin

                this.tOp = tipoOp.update
                console.log('operação ',this.tOp) 
            }
            catch(error){
                console.log(error)
            }

            console.log(id)
    
            
        },

        novoCliente(){
            try{
                this.limparCampos()
                this.tOp = tipoOp.create
                console.log('operação ',this.tOp)                 
            }
            catch(error){
                console.log(error)
            }
            
        },



    },

    mounted(){        
        this.tOp = tipoOp.view

        //this.getDadosPagina(1)
        
        this.getClientes()
    }
}
</script>

<style scoped>
 
   #list-table{
       max-width: 1200px;
       margin: 0 auto;
   }
 

 
   #head-table, #det-rows, .det-row{
        display: flex;
       flex-wrap: wrap;
   }

   #head-table{
       font-weight: bold;
       padding: 12px;
       border-bottom: 3px solid #333;
   }

   .det-row{
       width: 100%;
       padding: 12px;
       border-bottom: 1px solid #ccc;
   }

   #head-table div, .det-row div{
       width: 25%
   }

   #head-table .order-id, #det-row .order-number{
       width: 5%;
   }


 .container{
      margin: 0 auto;
  }
   
   .order-id{
       margin: 0 auto;
   }

   .order-number{
       margin: 0 auto;
   } 



   .delete-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 auto;
      cursor: pointer;
      transition: .5s;
   }

   .delete-btn:hover{
       background-color: transparent;
       color:#222;
   }



   .alterar-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px;
      cursor: pointer;
      transition: .5s;
      
   }

   .alterar-btn:hover{
       background-color: transparent;
       color:#222;
   }

   .novo-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px auto;
      cursor: pointer;
      transition: .5s;
      min-width: 90px;
      
   }

   .novo-btn:hover{
       background-color: transparent;
       color:#222;
   }



   .painel-superior{
      
       float:right;
       
            
   } 

   .btn-custom{
       margin: 10 10 10 10;
   }


   .modal-active{
     display:block;
   }




</style>

