<template>

    <div class="form-row">
        <div class="form-group col-md-12">
            <label for="celular">WhatsApp</label>
            <input name="celular" v-model="celular" type="text" class="form-control" id="celular" placeholder="WhatsApp">
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-12">
            <label for="nome">Razão Social</label>
            <input name="nome" v-model="nome" type="text" class="form-control" id="nome" placeholder="Nome">
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-12">
            <label for="observacao">Observação</label>
            <input name="observacao" v-model="observacao" type="text" class="form-control" id="observacao" placeholder="Observação">
        </div>
    </div>






    

</template>


<script>
   export default{
      
      name: "ProspeccaoCliente", 
      Components:{

      }
   }
</script>

<style scoped>

</style>