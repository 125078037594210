export function CryptoJS(){
    return ''
}

export function tokenMD5(token){
    return CryptoJS.MD5(token)
}


export function validarCelular(numero){

    //let n = String(numero).replace(/[^\d]/g, '')
    
    if (numero.length  > 11){
           return false 
    }
    
    return true


}


export function validarCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') return true;
     
    if (cnpj.length != 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    
    var i, resultado;
    
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
          return false;
           
    return true;
 
    
    
}

export default {
    validarCNPJ, 
    validarCelular,
    CryptoJS,
    tokenMD5
}

/***
 * 
 * 
let b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]

    let c = String(cnpj).replace(/[^\d]/g, '')

    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false
    
    let i,n;
    
    for ( i = 0,  n = 0; i < 12; n += c[i] * b[++i]);

    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for ( i = 0, n = 0; i <= 12; n += c[i] * b[i++]);

    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
            return false      

    return true * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */